import React, {useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NavBar from './components/NavBar';
import NavBar2 from './components/NavBar2';
import Home from './components/Home';
import MyGallery from './components/UserGallery/MyGallery';
import MyFeaturedImage from './components/UserGallery/MyFeaturedImage';
import MyShoppingCart from './components/UserGallery/MyShoppingCart';
import MyOrders from './components/UserGallery/MyOrders';
import ImageGallery from './components/ImageGallery/ImageGallery';
import ColoringPage from './components/ImageGallery/ColoringPage';
import Logout from './components/Logout';
import Auth from './components/Auth';
import SignUp from './components/SignUp';
import Logo from './logo.svg';
import './App.css';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-QELVL4NV35'); // Replace with your Measurement ID


const App = () => {

    const dispatch = useDispatch();



    useEffect(() => {
        if (localStorage.getItem("token")) {
        fetch("https://test-api.stainedglassusa.com/persist", {
            headers: {
                'Access-Control-Allow-Origin':'*',
            "Authorization": `Bearer ${localStorage.token}`
            }
        })
        .then(r => r.json())
        .then((resp) => {
            if (resp.user) {
            dispatch({
                type: 'SET_USER',
                payload: resp
            });
            }
            else {
            dispatch({
                type: 'CLEAR_USER'
            });
            }
        })
        }
    }, [dispatch]);

    return (
        <div className="App">
            <div className="background"></div>
            <div id="topbar">
            <div className="call">
            <span>Call us for a Free Quote! <span className="green">619-651-2323</span></span>
            </div>
            <div className="blank">
            <span id="blank"></span>
            </div>
            <div>
            <a href="https://stainedglasswindows.com"><span>FAQ’S | OUR BLOG | ABOUT US | CONTACT </span>         </a>
             </div>
                             </div>
                             <div id="header">
            <div className="social">
            <span>
                <a href="https://www.facebook.com/ArtGlassWindows"><span id="facebook"><FacebookOutlinedIcon /></span></a>&nbsp;<a href="https://www.instagram.com/stainedglasswindows/"><span id="instagram"><InstagramIcon /></span></a>&nbsp;<a href="https://www.pinterest.com/artglasswindows/"><span id="pinterest"><PinterestIcon /></span></a></span>
                <p>Social Media Links: </p>                
            </div>
            <div className="LogoDiv">
                <a href='https://stainedglasswindows.com'>
            <span id="logo">
                <img src={Logo} alt="Logo" /></span>
                </a>
            </div>
            <div>
            <span><NavBar2/></span>         
             </div>
                             </div>    
                                                     
            <div className={'mygallery' ? "nav2" : "nav1"} >
            <NavBar/>
            </div>
            <div className="main-content">
                <Switch>
                <Route path="/login" component={Auth} /> 
                <Route path="/signup" component={SignUp} />
                <Route path="/logout" component={Logout} />
             <Route path="/mygallery" component={MyGallery} />
                <Route path="/" exact component={ Home } /> 
                <Route path="/images" exact component={ImageGallery} />
                <Route path="/coloringpage/:id" component={ColoringPage} />
                <Route path="/featuredimage/:id" component={MyFeaturedImage} />
                <Route path="/cart" component={MyShoppingCart} />
                <Route path="/orders" component={MyOrders} />
                
                </Switch>
            </div>
            <div id="footer">
                {'created by'} <a href="/">STGW</a> {'// 2022'}
            </div>
        </div>
    )
}

export default withRouter(App);

import React from 'react';
import { useSelector } from 'react-redux';
export default function Home() {
    const {token, user} = useSelector(state => state.auth);
    return (
        <div className="home">
            <div className="hero">
                <div className="box">
                <div className="left">
             <div className="HeroText"> 
            <h1 className='title'>Color your Own <br></br> Stained Glass</h1>
            <h2>make a <b>unique window</b> in a few clicks!</h2> 
            <div className="arrow-container"></div>
            <div className="arrow-right" style={{ display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.703" height="21.928"><path d="M1.056 21.928c0-6.531 5.661-9.034 10.018-9.375V18.1L22.7 9.044 11.073 0v4.836a10.5 10.5 0 0 0-7.344 3.352C-.618 12.946-.008 21 .076 21.928z" fill="red"/></svg>
            </div>
            <a href="/signup"><span className="HeroButton">Create an Account in 2 Minutes and Begin Coloring Your Masterpiece!</span></a>

            </div>
            </div>
            <div className="right">
            <img className="hero-image" src="/hermosa-new.gif" alt="Glass"/>
            </div>
            </div>
            </div>
            <div className="HeroText2">    
            <p>Our <b>Click'N Color&trade;</b> online tool helps you create</p>
            <p>a window tailored to your needs within minutes</p>
            </div>
            <h2 className="steps-h2"> it's as easy as it can be!</h2>
            <div className="grid-container-3">
  <div className="grid-item"><p className="steps"><span className="circle">1</span>Select a design <br></br><small className="small-steps">Professionally designed styles</small></p></div>
  <div className="grid-item"><p className="steps"><span className="circle">2</span>Customize it<br></br><small className="small-steps">Change the Size, Form and Color</small></p></div>  
  <div className="grid-item"><p className="steps"><span className="circle">3</span>Get a Free Quote<br></br><small className="small-steps">Get a Quote in minutes!</small></p></div>
</div>
<hr className="hr"></hr>      
<h2 className="steps-h2"> Ready to Get started?</h2>
<p className="small-p-title">Just select one of our masterpiece designs to start customizing it online</p>
<p className="p-title">Each of our award-winning designs features a unique Beveled Cluster Glass.</p>
<div className="grid-container-6">
    <div className="grid-item"><img className="image-fixed" alt="Bakersfield" src="/Bakersfield.jpg"></img></div>
    <div className="grid-item"><img className="image-fixed" alt="Danville" src="/Danville.jpg"></img></div>
    <div className="grid-item"><img className="image-fixed" alt="Habra" src="/Habra.jpg"></img></div>
    <div className="grid-item"><img className="image-fixed" alt="Hermosa" src="/Hermosa.jpg"></img></div>
    <div className="grid-item"><img className="image-fixed" alt="Oakley" src="/Oakley.jpg"></img></div>
    <div className="grid-item"><img className="image-fixed" alt="PalmSprings" src="/PalmSprings.jpg"></img></div>
</div>
<hr className="hr"></hr>   
            <div className="grid-container-2">
                <div className="grid-item">
                <img className="image-fixed-color" alt="handcrafted" src="/handcrafted.jpg"></img>
                </div>
                <div className="grid-item">
                    <p className="small-p">Every panel is handmade right in our studio</p>
                    <h3>Handcrafted &#38;<br></br>Custom made <br></br> Stained Glass</h3>
                    <div className="arrows">
                    <ul>
                        <li>Stained Glass at great Prices</li>
                        <li>High-quality materials</li>
                        <li>Fast Lead times</li>
                        <li>Crate Shipping for extra care</li>
                        <li>The easiest way for creating a unique piece</li>

                    </ul>
                    </div>
                </div>

            </div>
            <div className="grid-container-2">
                <div className="grid-item">
                
                <p className="small-p">The perfect addition to any decor</p>
                    <h3>Enhance the look of<br></br>any room or space!</h3>
                </div>
                <div className="grid-item">
                <img className="image-fixed-color"alt="enhance" src="/enhance.jpg"></img>
                </div>

            </div>

        </div>
    )
}


import React, { useState } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {saveSvgAsPng} from 'save-svg-as-png';
import swal from 'sweetalert';
import ColoredInImage from '../Images/ColoredInImage';
import LoadingSpinner from '../LoadingSpinner';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PaletteIcon from '@mui/icons-material/Palette';
import CollectionsIcon from '@mui/icons-material/Collections';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { alignProperty } from '@mui/material/styles/cssUtils';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

export default function MyFeaturedImage(props) {
    let {id} = useParams();
    const {token} = useSelector(state => state.auth);
    const [userImage, setUserImage] = useState({});
    const [fetched, setFetched] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const addedToCart = userImage.cart
    const ordered = userImage.status
    
    if(!localStorage.getItem("token")){
        history.push("/login")
    }
    
    if (token && !fetched) {
        fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
            headers: {'Access-Control-Allow-Origin':'*','Authorization': `bearer ${token}`}
        })
        .then(r => r.json())
        .then(data => {
            setUserImage(data)
            setFetched(true)
        })   
    }

    if (!fetched) {
        return(
           <LoadingSpinner />
        )
    }

    if (fetched && !userImage.id) {
        return (
            <h1>Image not found</h1>
        )
    }

    const deleteImage = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this image",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
                    method: 'DELETE',
                    headers: {'Access-Control-Allow-Origin':'*','Authorization': `bearer ${token}`}
                })
                .then(r => r.json())
                .then(data => {
                    dispatch({
                        type: 'SET_IMAGES',
                        payload: data
                    });
                    swal("Image Deleted", "This image has been removed from your gallery", "success");
                    history.push(`/mygallery`)
                })        
            }
          });
    }

    
    const addToCart = () => {

        {userImage.cost <= 0 ?
            swal({
                title: "Window Size not defined",
                text: "Please select window size before adding to the cart",
                icon: "warning",
                buttons: false,
                dangerMode: true,
            })

            :


        swal({
            title: "Do you Want to Add this image to Cart?",
            text: "You can review all the details on the Cart Page",
            icon: "info",
            buttons: true,
            dangerMode: false,
            
        })
        .then((willAddtoCart) => {
            if (willAddtoCart) {

                fetch(`https://test-api.stainedglassusa.com/user_images/${id}`,{
                    method: 'PATCH',
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${token}`},
                    body: JSON.stringify({id: userImage.id, cart: true })
                })
                .then(r => r.json())
                .then(console.log)   
                
                    swal("Image Added", "This image has been added to your cart", "success");
                    history.push(`/cart`)
                }        
            }
          );
    }
}

    const editImage = () => {
        history.push(`/coloringpage/${userImage.id}`)
    }

    const backToGallery = () => {
        history.push('/mygallery')
    }

    const saveImage = () => {
        saveSvgAsPng(document.getElementById("svg-image"), `myimage_${userImage.id}.png`);
        swal("Download Complete", "This image has been saved to your Downloads", "success");
    }


    const isWhite = (currentValue) => currentValue === "white";
    const array1 = userImage.fill_colors;



    return(
        
        <div className="featured-image-page">
            <div className="featured-image-container"  style={{ pointerEvents: 'none' }}>
            <ColoredInImage
    size={'large'}
    component={userImage.image.component}
    fillColors={userImage.fill_colors}
    style={{ pointerEvents: 'none' }}
/>
                <h4 style={{ textAlign: "center" }}>Reference Image: {userImage.id}</h4>
            </div>


        {!array1.every(isWhite) ?  

            <div id="featured-btn">


        
        {ordered === "coloring" ?

        <Tooltip title="Color this Design">                    
        <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<PaletteIcon />}
        onClick={editImage}>
        Edit this Design
      </Button> 
      </Tooltip>                 
      :
      <Button color="success">
 Order Placed
</Button>
    }  
        <Tooltip title="Download">                    
                <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<DownloadForOfflineIcon />}
        onClick={saveImage}>
        Download
      </Button> 
      </Tooltip>      
      <Tooltip title="Back To Gallery">
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<CollectionsIcon />}
       onClick={backToGallery}>
        Back to Gallery
      </Button> 
      </Tooltip>  

      {/*!addedToCart && ordered === "coloring" ? 
      <Tooltip title="Add to Cart">
                <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddShoppingCartIcon />}
        onClick={addToCart}>
        Add to Cart
      </Button>     
      </Tooltip> 
      :
      <Button variant="contained" disabled>
  Item in Cart
</Button>
    */}
          {ordered === "coloring" ?        
      <Tooltip title="Delete">
                <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<DeleteForeverIcon />}
        onClick={deleteImage}>
        Delete
      </Button>     
      </Tooltip>   
      :
      <Tooltip title="Cannot Delete, Order Placed">

      <Button disabled
      variant="contained"
      color="secondary"
      className={classes.button}
      startIcon={<DeleteForeverIcon />}
      onClick={deleteImage}>
      Delete
    </Button>   
    </Tooltip>     
    } 


            </div>   
            
            :

            <div id="featured-btn">

        <Tooltip title="Color this Design">                    
        <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<PaletteIcon />}
        onClick={editImage}>
        Color this Design
      </Button> 
      </Tooltip>                 
     
      <Tooltip title="Back To Gallery">
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<CollectionsIcon />}
       onClick={backToGallery}>
        Back to Gallery
      </Button> 
      </Tooltip>  
      <Tooltip title="Cannot Delete, Order Placed">

<Button 
variant="contained"
color="secondary"
className={classes.button}
startIcon={<DeleteForeverIcon />}
onClick={deleteImage}>
Delete
</Button>   
</Tooltip>  


            </div>   


}
            
        </div>
    )
}
import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import CollectionsIcon from '@mui/icons-material/Collections';
import LogoutIcon from '@mui/icons-material/Logout';


export default function NavBar() {
    const {token} = useSelector(state => state.auth);
    const [mobileActive, setMobileActive] = useState(false); 
    const activeClass = mobileActive ? "active" : ""


    const toggleActive = () => {
        setMobileActive(!mobileActive)
    }
    
    return (
        <>
        <img src="/white-hamburger-menu.png" alt="mobile menu" id="hamburger-menu" onClick={toggleActive}/>
        {(!token) ?
            ( 
            <ul className={`nav-bar ${activeClass}`}>
                
            <li>
            <a href="https://www.stainedglasswindows.com/"><span id="instagram">Home</span></a>
               {/*  <NavLink onClick={toggleActive} className="nav-bar-link" to="/main">Home</NavLink>            */}
            </li>

            <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">
            <li>
                Designs
            </li>
            </NavLink>
            <li className='login-mobile'> 
            <NavLink onClick={toggleActive} className="nav-bar-link" to="/login">Login/Create Account</NavLink>
            </li>
            </ul>
            )
        :
            (
            <ul className={`nav-bar ${activeClass}`}>
                <li>
                <a href="https://www.stainedglasswindows.com/"><span id="instagram">Home</span></a>
                {/*  <NavLink onClick={toggleActive} className="nav-bar-link" to="/main">Home</NavLink>            */}
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/mygallery"><CollectionsIcon/> My Saved Designs</NavLink>
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/images">Designs</NavLink>
                </li>                
                <li className='logout-mobile'>
                <NavLink className="nav-bar-link" to="/logout"><LogoutIcon/> Log Out</NavLink>
                </li>
                </ul>
            )}
                {/* <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/cart">My Cart</NavLink>
                </li>
                <li>
                <NavLink onClick={toggleActive} className="nav-bar-link" to="/orders">My Orders</NavLink>
                </li> */}

        </>

    )
};
import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Button from '@material-ui/core/Button';

//import BasicModal from "./BasicModal"

class InstalationOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
    }
    
    render() {


        const data = this.props.data

        const Installation = () => {


            if(data === 'easy') {
                return (
                    <>
                    <img src="/easy.jpg" alt="easy" style={{ width: '100%' }} />
                    <p className="installation">The stained glass panel is set against your existing interior window glass and then held in place by tacking in quarter-round molding.</p>
                    <Button variant="contained" color="primary" onClick={() => this.setState({ openModal: true })}>
                        Learn More
                    </Button>
                    <Modal open={this.state.openModal} onClose={() => this.setState({ openModal: false })} center>
                        <h2>Minimal Installation Instructions:</h2>
                        <p><strong>Measure the Interior Glass</strong><br />
                        Start by measuring the interior dimensions of your existing glass.</p>

                        <p><strong>Prepare the Stained Glass Panel</strong><br />
                        Have the stained glass panel custom-made to be 1/4 to 3/8 inch smaller than the measured interior opening.</p>

                        <p><strong>Install the Stained Glass Panel</strong></p>
                        <ul>
                            <li>Position the stained glass panel against the existing glass.</li>
                            <li>Secure it in place using one of the following methods:
                                <ul>
                                    <li><strong>Quarter-round moulding:</strong> Trim around the edges for a clean finish.</li>
                                    <li><strong>Caulking:</strong> Apply caulk to hold the panel securely.</li>
                                    <li><strong>L-clips:</strong> Use these clips to fasten the panel into position.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>This simple installation method ensures a seamless and elegant look.</p>
                        <div className="row">
                            <div className="column" style={{ flex: '1 1 33%' }}>
                                <img src="https://stainedglasswindows.com/wp-content/uploads/2019/03/q14a_new.jpg" alt="Stained Glass 1" style={{ width: '100%' }} />
                            </div>
                            <div className="column" style={{ flex: '1 1 33%' }}>
                                <img src="https://stainedglasswindows.com/wp-content/uploads/2019/03/q14b_new.jpg" alt="Stained Glass 2" style={{ width: '100%' }} />
                            </div>
                            <div className="column" style={{ flex: '1 1 33%' }}>
                                <img src="https://stainedglasswindows.com/wp-content/uploads/2019/03/q14c_new.jpg" alt="Stained Glass 3" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </Modal>
                    </>                    
                );
            }else if(data === 'hang') {
                return (
                <><img src="/hang.jpg" alt="hang" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is delivered in a single pane format and affixed with hooks for easy hanging (hardware included).</p></>               
                );
            }else if(data === 'insulated') {
                return (
                    <>
    <div class="triple">
                    <img src="/duraseal.jpg" alt="insulated" style={{width: '100%'}}/>
{/*<BasicModal></BasicModal>*/}
  <p class="quanex">Highest quality Seal available on the market <br></br><span> Duraseal® warm-edge spacer</span></p>
</div>
                    </>                    
                    );
            }else if (data === 'vinylframed') {
                return (
                    <><img src="/vinyl-frame.jpg" alt="framed" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is fully insulated (triple paned) and pre-installed in a vinyl frame- Install ready!</p></>
                    );
            }else if (data === 'woodframed') {
                return (
                    <><img src="/wood-frame.jpg" alt="framed" style={{width: '100%'}}/>
                    <p className="installation">The stained glass is fully insulated (triple paned) and pre-installed in a wood frame- Install ready!</p></>
                    );
            }
        }

        return (
            Installation()
        );
    }
  }
    

export default InstalationOptions;
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
//import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';

export default function Auth() {

    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);

/*    const login = useGoogleLogin({
        
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            console.log(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    

    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [user]);
    */
    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        //googleLogout();
        setProfile(null);
    };

    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    const [form, setForm] = useState({ username: '', password: '' });

    const dispatch = useDispatch();
    let history = useHistory();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const endpoint = '/login';
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form)
        };
        fetch(`https://test-api.stainedglassusa.com/${endpoint}`, config)
        .then(r => r.json())
        .then(user => {
            if (user.error) {
                swal("User not found", "Please try entering your info again", "error");
                return;
            }
            dispatch({
                type: 'SET_USER',
                payload: user
            });
            swal("Login Success", `Welcome ${form.username}!`, "success");
            history.push("/mygallery");
        });
    }

    const handleGoogleLogin = (response) => {
        console.log(response);
        const endpoint = '/login';
        const config = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(response)
        };
        fetch(`https://test-api.stainedglassusa.com/${endpoint}`, config)
        .then(r => r.json())
        .then(user => {
            if (user.error) {
                swal("User not found", "Please try again", "error");
                return;
            }
            dispatch({
                type: 'SET_USER',
                payload: user
            });
            swal("Login Success", `Welcome ${user.username}!`, "success");
            history.push("/mygallery");
        });
    }

    const handleCreateAccount = () => {
        history.push("/signup");
    }

    const handleFacebookLogin = () => {
        // Handle Facebook login flow
        // You can use a library like react-facebook-login for this
    }

    return (
        <div>
            <div className="form-page" style={{ minHeight: '75vh' }}>
                <h3 className="login-signup-title">Login</h3>
                <form onSubmit={handleSubmit} className="form">
                    <input
                        type="text"
                        name="username"
                        value={form.username}
                        onChange={handleChange}
                        placeholder="Username"
                        style={{ marginBottom: "10px" }}
                        className="input"
                    />
                    <input
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className="input"
                    />
                    <input type="submit" id="submit-button" value="Login" />
                </form>

                <div>
                <hr className="form-divider" />
                    {/* <button onClick={handleFacebookLogin}>Login with Facebook</button> */}

                    {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                // <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                null
            )} */}
                </div>
                <hr className="form-divider" />
                <h4>Don't have an account? </h4>
                <button onClick={handleCreateAccount} className="HeroButton">Create a free account</button>
                <p>It just takes a minute and you will start coloring your masterpiece free!</p>

            </div>
            <div className="footer"></div>
        </div>
    );
}
